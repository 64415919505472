import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import './home.css';

const Home = () => {
    return (
        <div id="home_page">

            <Row>
                <Col>
                    <div className="header text-center">
                        <h2>Select Service</h2>
                    </div>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col sm="6" md="6" lg="6" className="text-end">
                    <div>
                        <Link className="link" to="/ocr/pan">
                            <div className="link_text">OCR</div>
                        </Link>
                    </div>
                </Col>

                <Col sm="6" md="6" lg="6" className="text-start">
                    <div>
                        <Link className="link" to="/validation-suite/ifsc">
                            <div className="link_text">Validation Suite</div>
                        </Link>
                    </div>
                </Col>

                <Col sm="6" md="6" lg="6"></Col>
            </Row>
        </div>
    );
}

export default Home;