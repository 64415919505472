import './App.css';
import Layout from './Layouts/Layout';
import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from './pages/NoPage';
import Login from './pages/components/Login';
import Ocr from './pages/Ocr';
import Pan from './pages/Ocr/Pan';
import Aadhaar from './pages/Ocr/Aadhaar';
import { PageLoaderContext } from './context/PageLoaderContext';
import ValidationSuite from './pages/ValidationSuite';
import Ifsc from './pages/ValidationSuite/Ifsc';
import Bank from './pages/ValidationSuite/Bank';
import PanValidation from './pages/ValidationSuite/PanValidation';
import VpaValidation from './pages/ValidationSuite/VpaValidation';
import AadhaarValidation from './pages/ValidationSuite/AadhaarValidation';

function App() {
    return (
        <PageLoaderContext>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Login />} />
                        <Route path="home" element={<Home />} />
                        <Route path="ocr" element={<Ocr />}>
                            <Route path="pan" element={<Pan />} />
                            <Route path="aadhaar" element={<Aadhaar />} />
                        </Route>
                        {/* <Route path="ocr/pan" element={<Pan />} /> */}
                        <Route path="validation-suite" element={<ValidationSuite />}>
                            <Route path="ifsc" element={<Ifsc />} />
                            <Route path="bank" element={<Bank />} />
                            <Route path="pan" element={<PanValidation />} />
                            <Route path="vpa" element={<VpaValidation />} />
                            <Route path="aadhaar" element={<AadhaarValidation />} />
                        </Route>
                        <Route path="*" element={<NoPage />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </PageLoaderContext>
    );
}

export default App;
