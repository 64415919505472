import axios from "axios"
import { apiStatusText, baseURL, clientId, clientIdValidate, clientSecret, clientSecretValidate, SERVICE_VALIDATE } from "../utility/config"


export const postRequest = (params) => {
    return new Promise(async (success, reject) => {
        try {

            let userToken = '';
            if (params?.service === SERVICE_VALIDATE) {
                userToken = window.btoa(`${clientIdValidate}:${clientSecretValidate}`);
            } else {
                userToken = window.btoa(`${clientId}:${clientSecret}`);
            }


            if (params?.contentType !== 'multipart/form-data') {
                params = {
                    url: params.url,
                    payload: {
                        ...params.payload
                        // reference: userAgentInfo()
                    }
                }
            }


            await axios.request({
                validateStatus: (status) => status,
                method: 'POST',
                url: `${baseURL}${params.url}`,
                data: params?.payload,
                headers:
                {
                    Authorization: userToken ? `Basic ${userToken}` : '',
                    // requestHash: hashHandler(endPoint),
                    "Content-Type": (params?.contentType) ? params.contentType : "application/json",
                    accept: "application/json",
                    "Access-Control-Allow-Origin": "*",
                    withCredentials: true,
                    'X-Requested-With': 'XMLHttpRequest'
                    // 'api-key-1': api_key_1,
                    // 'api-key-2': api_key_2
                }
            }).then((response) => {

                console.log('postRequest', params.url, response)

                // let isSuccess = true

                // switch (response.status) {
                //     case 200:
                //         if (response.data.status === apiStatusText.unauthorized) {
                //             isSuccess = false
                //             // toast(response?.data?.message || 'Session Expired. Please Login.', { type: 'error' })
                //             // clearStorageAll()
                //             // window.location.replace('/')
                //         }
                //         break

                //     case 401:
                //         isSuccess = false
                //         // clearStorageAll()
                //         // window.location.replace('/')
                //         break
                // }

                // if (isSuccess) {
                success(response)
                // }

            }).catch((error) => {
                console.log('getRequest: error', error)
                // if (error.response.status === 401) {
                //     // console.log('getRequest: error', error)
                //     // logoutExplicit();
                //     // toast('Session Expired. Please Login.', { type: 'error' });
                //     // window.location.replace('/');
                // }
                //  else {
                //     reject(error);
                // }
                reject(error)
            })
            // })
            // .catch(err => {
            //     reject(err)
            // })
        } catch (error) {
            console.log('getRequest: error', error)
            reject(error)
        }
    })
}


export const getRequest = (params) => {
    return new Promise(async (success, reject) => {
        try {

            let userToken = '';
            if (params?.service === SERVICE_VALIDATE) {
                userToken = window.btoa(`${clientIdValidate}:${clientSecretValidate}`);
            } else {
                userToken = window.btoa(`${clientId}:${clientSecret}`);
            }

            await axios.request({
                validateStatus: (status) => status,
                method: 'GET',
                url: `${baseURL}${params.url}`,
                params: (params?.payload),
                headers:
                {
                    Authorization: userToken ? `Basic ${userToken}` : '',
                    // requestHash: hashHandler(endPoint),
                    "Content-Type": "application/json",
                    accept: "application/json",
                    "Access-Control-Allow-Origin": ["https://*.xettle.io"],
                    withCredentials: true,
                    'X-Requested-With': 'XMLHttpRequest'
                    // 'api-key-1': api_key_1,
                    // 'api-key-2': api_key_2
                }
            }).then((response) => {
                console.log('getRequest', params.url, response)

                // let isSuccess = true

                // switch (response.status) {
                //     case 200:
                //         if (response.data.status === apiStatusText.unauthorized) {
                //             isSuccess = false
                //             // toast(response?.data?.message || 'Session Expired. Please Login.', { type: 'error' })
                //             // clearStorageAll()
                //             // window.location.replace('/')
                //         }
                //         break

                //     case 401:
                //         isSuccess = false
                //         // clearStorageAll()
                //         // window.location.replace('/')
                //         break
                // }

                // if (isSuccess) {
                success(response)
                // }

                // success(response)
                // switch (response.status) {

                //     case 200:

                //         if (response.data.code === apiStatusCodes.successX || response.data.status === 'UNAUTHORIZED') {
                //             toast(response?.data?.message || 'Session Expired. Please Login.', { type: 'error' });
                //             logoutExplicit();
                //             window.location.replace('/');
                //         } else if (response?.data?.status === apiStatusText.maintenance) {
                //             // logoutExplicit();
                //             storeUnderMaintenance(apiStatusText.maintenance);
                //             window.location.replace('/');
                //         } else if (response.data.code === apiStatusCodes.success) {
                //             // success({ success: response.data.data });
                //             success({ success: response.data?.data ? response.data.data : response.data });
                //         } else if (response.data.code === apiStatusCodes.failed) {
                //             success({ failed: response.data });
                //         } else if (response.data.code === apiStatusCodes.pending) {
                //             success({ pending: response.data });
                //         } else if (response.data.code === apiStatusCodes.warning) {
                //             success({ warning: response.data });
                //         }
                //         break;

                //     default:
                //         success({ error: response.data });
                // }

            }).catch((error) => {
                console.log('getRequest: error', error)
                // if (error.response) {
                //     console.log(error.response.data)
                //     console.log(error.response.status)
                //     console.log(error.response.headers)
                // } else {
                //     reject(error)
                // }
                // if (error.response.status === 401) {
                //     logoutExplicit();
                //     toast('Session Expired. Please Login.', { type: 'error' });
                //     window.location.replace('/');
                // } else {
                //     reject(error)
                // }
                reject(error)
            })

        } catch (error) {
            console.log('getRequest: try_catch: ', error)
            reject(error)
        }
    })
}