import { useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { PageLoader } from "../../context/PageLoaderContext";
import { postRequest } from "../../Services/httpService";
import { apiUrls, statusCode } from "../../utility/config";

import './ocr.css';

const Pan = () => {

    const { setShowLoader } = useContext(PageLoader);

    const [url, setUrl] = useState('');
    const [apiResponse, setApiResponse] = useState(null);

    const formSubmitHandler = () => {
        // alert(`DMO_${new Date().valueOf()}`);

        if (url === '' || url === undefined || url === null) {
            alert('Please enter a valid URL!');
            return;
        }

        setShowLoader(true);

        postRequest({
            url: apiUrls.ocr.pan,
            payload: {
                clientRefId: `DMO_${new Date().valueOf()}`,
                pan: url
            }
        }).then(response => {
            console.log(response);

            if (response.data.code === statusCode.success) {
                setApiResponse(response.data);
            } else {
                alert(response.data.message);
            }

            setShowLoader(false);
        })
    }


    return (
        <>
            <div id="ocr_page_pan">

                <div className='content'>

                    <Row>
                        <Col sm="6" md="6">
                            <h5>PAN OCR Request:</h5>

                            <div className='form'>

                                {/* <Form> */}
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>PAN URL: <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="url" placeholder="Enter url" value={url} onChange={e => setUrl(e.target.value)} />
                                </Form.Group>

                                <Button variant="primary" type="submit" className='mt-3 float-end' onClick={formSubmitHandler}>
                                    Submit
                                </Button>
                                {/* </Form> */}

                            </div>
                        </Col>

                        <Col sm="6" md="6">
                            <div className="api_response">
                                <h5>API Response:</h5>
                                <div className='api_response_content'>
                                    {apiResponse && (<pre>{JSON.stringify((apiResponse), null, 4)}</pre>)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default Pan;