export const baseURL = process.env.REACT_APP_API_BASEURL;
export const clientId = process.env.REACT_APP_OCR_CLIENTID;
export const clientSecret = process.env.REACT_APP_OCR_SECRET;
export const clientIdValidate = process.env.REACT_APP_VALIDATE_CLIENTID;
export const clientSecretValidate = process.env.REACT_APP_VALIDATE_SECRET;
export const userName = process.env.REACT_APP_USERID;
export const userPass = process.env.REACT_APP_USERPWD;

export const SERVICE_VALIDATE = 'validation';


export const apiUrls = {
    ocr: {
        pan: '/v1/service/ocr/pan',
        aadhaar: '/v1/service/ocr/aadhaar',
    },
    validate: {
        ifsc: '/v1/service/verification/ifsc',
        bank: {
            init: '/v1/service/verification/bank/initiate',
            status: '/v1/service/verification/bank/check'
        },
        pan: {
            init: '/v1/service/verification/pan/initiate',
            status: '/v1/service/verification/pan/check'
        },
        vpa: {
            init: '/v1/service/verification/vpa/initiate',
            status: '/v1/service/verification/vpa/check'
        },
        aadhaar: {
            init: '/v1/service/verification/aadhaar/initiate',
            status: '/v1/service/verification/aadhaar/otp'
        }
    }
}


export const statusCode = {
    success: '0x0200',
    failed: '0x0202',
    missing: '0x0203'
}


export const apiStatusCodes = {
    success: '0x0200',
    failed: '0x0202',
    missing: '0x0203'
}


export const apiStatusText = {
    unauthorized: 'UNAUTHORIZED',
    maintenance: 'maintenance'
}


export const appUrls = {
    user: {
        profile: '/user/profile',
        profileUpdate: '/user/profile/update',
        kycUpdate: '/user/profile/kyc',
        setting: '/user/settings'
    }
}