import { createContext, useState } from "react";

const PageLoader = createContext()

const PageLoaderContext = ({ children }) => {

    const [showLoader, setShowLoader] = useState(false);
    const [isUserLogin, setIsUserLogin] = useState(false);

    return (
        <PageLoader.Provider value={{ showLoader, setShowLoader, isUserLogin, setIsUserLogin }}>
            {children}
        </PageLoader.Provider>
    )
}


export { PageLoader, PageLoaderContext };