import { Link, Outlet } from "react-router-dom";
import './ocr.css';

const Ocr = () => {
    return (
        <>
            <div id="ocr_page">
                <div className="header my-4 text-center">
                    <nav className="subpage_nav">
                        <ul>
                            <li>
                                <Link to="/ocr/pan">PAN</Link>
                            </li>
                            <li>
                                <Link to="/ocr/aadhaar">AADHAAR</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <Outlet />
        </>
    );
}

export default Ocr;