import { Link, Outlet } from "react-router-dom";

import './validation_suite.css';

const ValidationSuite = () => {
    return (
        <>
            <div id="validation_suite_page">
                <div className="header my-4 text-center">
                    <nav className="subpage_nav">
                        <ul>
                            <li>
                                <Link to="/validation-suite/ifsc">IFSC</Link>
                            </li>
                            <li>
                                <Link to="/validation-suite/aadhaar">AADHAAR</Link>
                            </li>
                            <li>
                                <Link to="/validation-suite/bank">Bank</Link>
                            </li>
                            <li>
                                <Link to="/validation-suite/pan">PAN</Link>
                            </li>
                            <li>
                                <Link to="/validation-suite/vpa">VPA</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <Outlet />
        </>
    );
}

export default ValidationSuite;