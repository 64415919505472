import { useContext, useEffect } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import { PageLoader } from "../context/PageLoaderContext";

import './layout.css';

const Layout = () => {

    const location = useLocation();

    const { showLoader, isUserLogin } = useContext(PageLoader);

    useEffect(() => {

        if (location.pathname !== '/') {
            if (isUserLogin !== true) {
                window.location.replace('/');
            }
        }
    }, []);

    return (
        <div className="container" id="layout_page">
            <div className={`overlay_container ${!showLoader ? 'd-none' : ''}`}>
                <div className="loader_conatiner">
                    <div className="spinner">Loading...</div>
                </div>
            </div>
            <div className="header my-4">
                <nav>
                    {isUserLogin === false && (
                        <ul className="p-0 mt-4">
                            <li>
                                <Link to="/">
                                    <img src="/xettle.png" width="36" alt="XETTLE" />
                                    <span className="logo_text">XETTLE</span>
                                </Link>
                            </li>
                        </ul>
                    )}

                    {isUserLogin === true && (<>
                        <ul className="p-0 mt-4 nav_ul">
                            <li>
                                <Link to="/home">
                                    <img src="/xettle.png" width="36" alt="XETTLE" />
                                    <span className="logo_text">XETTLE</span>
                                </Link>
                            </li>
                            <li className="nav_li">
                                <Link to="/ocr/pan">OCR</Link>
                            </li>
                            <li className="nav_li">
                                <Link to="/validation-suite/ifsc">Validation Suite</Link>
                            </li>
                        </ul>
                    </>)}
                </nav>
            </div>


            <Outlet />
        </div >
    )
};

export default Layout;